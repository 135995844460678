<template>
    <div>
        <div class="pageheader pd-t-25 pd-b-15">
            <div class="row">
                <div class="col-md-3 mb-2 ">
                    <div class="pd-t-5 pd-b-5">
                        <h1 class="pd-0 mg-0 tx-20 text-overflow">Data Survey Alumni</h1>
                    </div>
                    <div class="breadcrumb pd-0 mg-0">
                        <a class="breadcrumb-item" href="#"><i class="fa fa-users mg-r-5"></i><span class="breadcrumb-item active"> Data Survey Alumni</span></a>
                    </div>
                </div>
            </div>
            
        </div> 
        
        <!-- MAIN CONTENT ================================================== -->
        <div class="row justify-content-center">
            <div class="col-12 col-lg-12 col-xl-12">
                <!-- CONTENT -->
                <div class="tab-content mb-3">
                    <div class="tab-pane show active" id="anggota-tab" role="tabpanel">
                        <div class="card">
                            <div class="card-header">
                                <div class="col-md-4 pl-0 pr-0">
                                    <div class="input-group input-group-flush input-group-merge">
                                        <div class="input-group-prepend">
                                            <div class="input-group-text">
                                                <span class="fe fe-users mr-2"></span>{{ totalPengguna }} Survey
                                            </div>
                                        </div>
                                        <input type="search" class="form-control form-control-prepended search" v-model="keywords" v-on:keyup.enter="getData()" placeholder="Cari Alumni...">
                                        <div class="input-group-prepend">
                                            <div class="input-group-text">
                                                <span class="fe fe-search"></span>
                                            </div>
                                        </div>
                                        <!-- HEADER -->
                                    </div>
                                </div>
                                <div class="ml-2 col-md-3 pl-0 pr-0">
                                    <select @change.prevent="getData()" v-model="filterPengutamaan" class="form-control">
                                        <option value="">- Semua Pengutamaan -</option>
                                        <option v-for="(item, index) in dataPengutamaan" :key="index" :value="item.id">{{ item.nama_pengutamaan }}</option>
                                    </select>
                                </div>
                                <div class="ml-2 col-md-3 pl-0 pr-0">
                                    <download-excel :fetch="getDataExport" :name="fileName" :header="header">
                                        <button class="btn btn-success text-sm"><span class="fe fe-download mr-2"></span>Export</button>
                                    </download-excel>
                                </div>
                            </div>
                            <div class="table-responsive">
                                <table class="table table-xl table-responsive-sm">
                                    <thead>
                                        <tr>
                                            <th rowspan="2">No</th>
                                            <th rowspan="2">Identitas</th>
                                            <th rowspan="2">Pendidikan</th>
                                            <th rowspan="2">Pekerjaan & Instansi</th>
                                            <th rowspan="2">Kesesuaian Pekerjaan</th>
                                            <th rowspan="2">Lama Peroleh Pekerjaan/Usaha</th>
                                            <th rowspan="2">Publikasi Ilmiah</th>
                                            <th rowspan="2">Penghargaan Nasional/Internasional</th>
                                            <th colspan="5" class="text-center">Masukan untuk Prodi</th>
                                            <th rowspan="2">Waktu Pengisian</th>
                                        </tr>
                                        <tr>
                                            <th>Kurikulum</th>
                                            <th>Sarana & Prasarana</th>
                                            <th>Staf Akademik (dosen)</th>
                                            <th>Proses, suana & standar pembelajaran</th>
                                            <th>Masukan</th>
                                        </tr>
                                    </thead>
                                    <tbody v-if="dataPengguna.length > 0">
                                        <tr v-for="(user, index) in dataPengguna" :key="index">
                                            <td>{{ (index + 1) + (pagination.currentPage - 1) * pagination.limit }}</td>
                                            <td>
                                                {{ user.name }}<br>
                                                <span class="pt-0 pb-0 btn btn-sm btn-oblong btn-outline-info">{{ user.pengutamaan }}</span> | {{ user.email }} <br>
                                            </td>
                                            <td>
                                                Tahun Masuk : {{ user.tahun_masuk }}<br>
                                                Tahun Lulus : {{ user.tahun_lulus }}
                                            </td>
                                            <td>
                                                {{ user.pekerjaan }}<br>
                                                <span class="pt-0 pb-0 btn btn-sm btn-oblong btn-secondary">{{ user.instansi }}</span> <br>
                                            </td>
                                            <td>
                                                <span v-if="user.kesesuaian == 1">Kesesuaian Tinggi</span>
                                                <span v-if="user.kesesuaian == 2">Kesesuaian Sedang</span>
                                                <span v-if="user.kesesuaian == 3">Kesesuaian Rendah</span>
                                            </td>
                                            <td>
                                                <span v-if="user.berkarir == 1">Kurang dari 1 Bulan</span>
                                                <span v-if="user.berkarir == 2">Antara 1-3 Bulan</span>
                                                <span v-if="user.berkarir == 3">Antara 3-6 Bulan</span>
                                                <span v-if="user.berkarir == 4">Antara 6-12 Bulan</span>
                                                <span v-if="user.berkarir == 5">Lebih dari 12 Bulan</span>
                                            </td>
                                            <td>{{ user.karya }}</td>
                                            <td>{{ user.kegiatan }}</td>
                                            <td>{{ user.kurikulum }}</td>
                                            <td>{{ user.saran }}</td>
                                            <td>{{ user.dosen }}</td>
                                            <td>{{ user.proses }}</td>
                                            <td>{{ user.masukan }}</td>
                                            <td>{{ user.updated_at }}</td>
                                        </tr>
                                    </tbody>
                                    <tbody v-if="dataPengguna.length == 0">
                                        <tr>
                                            <td colspan="13" class="text-center">--- Tidak Ada Data Survey ---</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div class="card-footer border-top-0">
                                <div style="float:right">
                                    <!-- <ul class="pagination mb-0"></ul> -->
                                    <v-pagination v-if="pagination.totalPages > 0" v-model="pagination.currentPage"
                                                :page-count="pagination.totalPages"
                                                :classes="pagination.bootstrapPaginationClasses"
                                                :labels="pagination.paginationAnchorTexts"
                                                v-on:change="getData()"></v-pagination>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
    import vPagination from 'vue-plain-pagination'

    export default {
        metaInfo: {
            title: 'FTSL',
            titleTemplate: '%s - Data Kemajuan'
        },
        data() {
            return {
                baseUrl: this.$apiconfig,
                printUrl: 'https://print.drei-system.com/',
                fileName: "Data Survey Alumni",
                header: "Data Survey Alumni",
                dataPengguna: [],
                dataPengutamaan: [],
                fetching: true,
                filterPengutamaan: '',
                pagination: {         
                    currentPage: 1,
                    totalPages: 0,
                    limit: 7,
                    bootstrapPaginationClasses: {
                        ul: 'pagination',
                        li: 'page-item',
                        liActive: 'active',
                        liDisable: 'disabled',
                        button: 'page-link'  
                    },
                    paginationAnchorTexts: {
                        first: '<i class="fe fe-chevrons-left"></i>',
                        prev: '<i class="fe fe-chevron-left"></i>',
                        next: '<i class="fe fe-chevron-right"></i>',
                        last: '<i class="fe fe-chevrons-right"></i>'
                    }
                },
                keywords: '',
                showSelectYear: true
                
            }
        },
        created() {
            this.getData();
            this.getPengutamaan();
        },
        components: {
            vPagination,
        },
        methods: {
            getData() {
                this.fetching = true;
                this.$http.get(this.baseUrl + 'admin/tracer', {
                    params: {
                        page : this.pagination.currentPage,
                        keywords: this.keywords,
                        filterTahapan: this.filterTahapan,
                        filterPengutamaan: this.filterPengutamaan,
                    }
                })
                    .then((response) => {
                        this.dataPengguna  = response.data.data;
                        this.totalPengguna = response.data.total;
                        this.fetching = false;
                        this.pagination.totalPages = response.data.total_page;
                    })
                    .catch((error) => {
                        console.log(error.response.data);
                        this.fetching = false;
                    });
            },

            getPengutamaan() {
                this.fetching = true;
                this.$http.get(this.baseUrl + 'admin/master/pengutamaan', {
                    params: {
                        page : this.pagination.currentPage,
                        keywords: this.keywords,
                    }
                })
                    .then((response) => {
                        this.dataPengutamaan = response.data.data;
                    })
                    .catch((error) => {
                        console.log(error.response.data);
                    });
            },

            async getDataExport() {
                const response = await this.$http.get(this.baseUrl + `admin/tracer/export`, {
                    params: {
                        filterPengutamaan: this.filterPengutamaan,
                        keywords: this.keywords,
                    }
                });
                return response.data.data;
            }
        },
    };
</script>
<style>
    /* style sweetalert */
    .swal2-html-container {
        display: block;
        text-align: center;
    }
</style>